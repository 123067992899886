<template>
  <b-card>
    <h5 class="mb-2 text-primary">
      {{ title }}
    </h5>
    <app-timeline>
      <app-timeline-item
        title="Salida"
        :subtitle="travelData.source"
        icon="Navigation2Icon"
        :time="`Aprox ${travelData.departure_date.split(' ')[0]} - ${travelData.departure_date.split(' ')[1]}`"
        variant="primary"
      />

      <app-timeline-item
        title="Llegada"
        :subtitle="travelData.destination"
        icon="MapPinIcon"
        :time="`Aprox ${travelData.arrival_date.split(' ')[0]} - ${travelData.arrival_date.split(' ')[1]}`"
        variant="success"
      />
    </app-timeline>
    <hr v-if="isActivePay">
    <h5
      v-if="isActivePay"
      class="mb-2 text-primary"
    >
      Resumen de pago
    </h5>
    <b-list-group
      v-if="isActivePay"
      flush
    >
      <b-list-group-item
        class="d-flex justify-content-between align-items-center"
      >
        <span>Subtotal</span>
        <span>$ {{ formatPrice(travelData.total_price) }} </span>
      </b-list-group-item>
    </b-list-group>
    <hr v-if="isActivePay">
    <b-list-group
      v-if="isActivePay"
      flush
    >
      <b-list-group-item
        class="d-flex justify-content-between align-items-center"
      >
        <h5>Total</h5>
        <h5>$ {{ formatPrice(travelData.total_price) }} </h5>
      </b-list-group-item>
    </b-list-group>
  </b-card>
</template>

<script>
import {
  BCard, BListGroup, BListGroupItem,
} from 'bootstrap-vue'

import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  components: {
    BCard,
    BListGroup,
    BListGroupItem,
    AppTimeline,
    AppTimelineItem,
  },
  props: {
    travelData: {
      type: Object,
      required: true,
      default: () => { },
    },
    title: {
      type: String,
      required: false,
      default: () => 'Viaje',
    },
    isActivePay: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  methods: {
    formatPrice(price) {
      return new Intl.NumberFormat('de-DE', { currency: 'COP' }).format(price)
    },
  },
}
</script>
